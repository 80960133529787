<template>
    <div>
        <BaseInfo :baseInfo="$store.state.user.baseInfo" />
        <NavBar
                title="上传动作评估视频"
                left-arrow
                @click-left="$router.go(-1)"
        />
        <div id="app" style="display: none">
            <div id="uploader" class="wu-example">
                <!--用来存放文件信息-->
                <div id="fileList" class="uploader-list"></div>

                <div class="btns">
                    <!-- 选择文件的按钮 -->
                    <!--                    <div id="picker">选择文件</div>-->

                    <!--                    <input  type="file" v-model="fileIn" @click="selFile()">-->

                    <!-- 开始上传按钮 -->
                </div>
            </div>
            <Button round block type="info"  id="uploadBtn" class="btn btn-default" @click="toupload" >开始上传</Button>

        </div>
        <cell-group style="display: none">
            <cell v-for="(filetemp,index) in fileList" :title="filetemp" :key="index">
            </cell>
        </cell-group>

        <vfrom @submit="onSubmit">
            <vfield
                    readonly
                    clickable
                    name="video_name_cn"
                    label="动作名称"
                    :value="video_name_cn"
                    placeholder="请选择"
                    @click="showPicker = true"
            />
            <Popup v-model="showPicker" position="bottom">
                <Picker
                        show-toolbar
                        :columns="opts"
                        :columns-field-names="customFieldName"
                        @confirm="onConfirm"
                        @cancel="showPicker = false"
                />
            </Popup>
            <vfield name="pain" label="是否有疼痛" required >
                <template #input >
                    <RadioGroup v-model="pain" direction="horizontal">
                        <Radio name="无" >无</Radio>
                        <Radio name="有" >有</Radio>
                    </RadioGroup>
                </template>
            </vfield>
            <vfield name="uploader" label="文件上传">
                <template #input>
                    <vuploader v-model="files" :max-count="1"
                               :after-read="afterRead"
                               :before-read="beforeRead"
                               :deletable="false"
                               accept="*/*"
                               :max-size="50 * 1024 * 1024"
                               :result-type="uploadtype"
                               @oversize="onOversize"/>
                </template>
            </vfield>
            <vfield>
                <template>
                    <div v-if="uploadPer > 0" style="padding-left: 10%;padding-right: 10%">
                        <Progress :percentage="uploadPer" />

                    </div>
                </template>
            </vfield>


            <div style="margin-left: 20px;margin-top: 10px;font-size:14px">
                <vcheckbox v-model="isagree" icon-size="12" shape="square" @change="changeAgree">
                    我已阅读并同意
                    <!--                    <a @click="showFWXY" style="color: #007aff">服务协议</a>、-->
                    <a @click="showYSZC" style="color: #007aff">《关节通隐私政策及协议》</a>
                </vcheckbox>

            </div>
            <div style="margin: 16px;">
                <Button round block type="danger" :disabled="substatus||agreeStatus" native-type="submit" >上传</Button>
            </div>
        </vfrom>
        <br>

        <video controls="controls"  width='100%;' height="381px" padding-top='-6px' id="videoInfo" :src="video_url">
            <source type="video/mp4"/>
        </video>
        <br>

        <div style="margin-left: 2em;margin-right: 2em;text-indent:2em;font-size:13px">

            <p style="color: black">
                朋友您好！
            </p>
            <p style="color: black">
                拍摄本视频有助于评估您的躯体活动能力，请按以下拍摄要求进行录制。
            </p>
            <p style="color: black">
                1、分别录制每个动作视频，动作节奏尽可能和演示视频一致。
            </p>
            <p style="color: black">
                2、保持摄像头固定，尽可能从水平视线进行录制。拍摄广度涵盖双手平举和上举的范围。
            </p>
            <p style="color: black">
                3、脱鞋，脱袜，穿着短裤短袖(显露出膝关节，踝关节，肘关节V)，保持着装颜色与背景色区分明显，便于识别动作完成情况。
            </p>
            <p style="color: black">
                4、动作过程中如有身体部位疼痛，也请在疼痛可耐受的情况下尽量完成动作，并在动作完成后指明疼痛的准确部位，以便分析体态异常或者动作完成不标准的原因。
            </p>
        </div>
        <br>
        <br>
        <!--        <vuploader :max-count="1" :after-read="afterRead" />-->
        <!--        <div style="margin: 16px;">-->
        <!--            <Button round block type="danger" native-type="submit" >上传</Button>-->
        <!--        </div>-->
        <ActionSheet v-model="showYszc" title="关节通隐私政策及协议">
            <div class="content" style="margin-left: 2em;margin-right: 2em;text-indent:2em;font-size:13px">
                <p></p>
                <p>1.适用范围</p>
                <p>1.1本政策仅适用于关节通微信公众号服务（以下简称“关节通”）的运营主体及客户端服务可能存在的运营关联单位，具体指深圳市瑞林泰克科技有限公司（以下简称“瑞林泰克”）。使用前述关节通服务的使用人在本政策中称为“用户”，或称为“您”。 </p>
                <p>1.2请您在使用微信公众号服务前，仔细阅读并充分理解本隐私保护政策注册协议。您在点击“确认”按钮后，本政策即构成对双方有约束力的法律文件，即表示您同意瑞林泰克按照本政策收集、使用、处理和存储您的相关信息。如果您对本隐私政策有任何疑问、意见或建议，可通过本政策第5条提供的联系方式与我们联系。 </p>
                <p>1.3瑞林泰克深知个人信息对您的重要性，并会尽全力保护您的个人信息安全可靠。我们致力于维持您对我们的信任，并恪守以下原则，保护您的个人信息：权责一致原则、目的明确原则、选择同意原则、最少够用原则、确保安全原则、主体参与原则、公开透明原则等。同时，瑞林泰克承诺，我们将按业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。</p>
                <p></p>
                <p>2.隐私保护政策具体内容</p>
                <p>2.1 我们如何收集和使用信息 </p>
                <p>我们会按照如下方式收集您在使用服务时主动提供的、以及通过自动化手段收集您在使用功能或接受服务过程中产生的信息： </p>
                <p>2.1.1注册问卷： </p>
                <p>当您关注公众号提交注册问卷时，注册问卷中个人信息和其他有无关节不适信息。 </p>
                <p>2.1.2沟通功能 </p>
                <p>医患沟通中咨询的运动训练及康复情况等交流信息。 </p>
                <p>2.1.3定向推送 </p>
                <p>我们会基于收集的信息，推送相关的科普图文、运动康复训练视频、量表。为此，我们需要收集的信息包括您的量表内容。 </p>
                <p>2.1.4安全运行 </p>
                <p>安全保障功能我们致力于为您提供安全、可信的产品与使用环境，提供优质而可靠的服务是我们的核心目标。读、写入外部存储，唯一标识符为实现安全运行所收集的必要权限和信息。 </p>
                <p></p>
                <p>2.2.我们如何共享、转让、公开披露您的个人信息 </p>
                <p>2.2.1共享 </p>
                <p>我们不会向瑞林泰克以外的任何公司、组织和个人分享您的个人信息，但以下情况除外： </p>
                <p>2.2.1.1在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息。 </p>
                <p>2.2.1.2我们可能会根据法律法规规定，或按政府主管部门的强制性要求，对外共享您的个人信息。  </p>
                <p>2.2.1.3与授权合作伙伴共享：仅为实现本政策中声明的目的，我们的某些服务将由授权合作伙伴提供。我们可能会与合作伙伴共享您的某些个人信息及问卷信息，以提供更好的客户服务和用户体验。例如，根据个人信息和问卷信息情况医师更有针对性咨询和推送相关视频及问卷。 </p>
                <p>目前，我们的授权合作伙伴包括各地区医疗服务类的授权合作伙伴。  </p>
                <p>2.2.2转让 </p>
                <p>我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外： </p>
                <p>2.2.2.1在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息； </p>
                <p>2.2.2.2在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会在要求新的持有您个人信息的公司、组织继续受此隐私政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。 </p>
                <p>2.2.3公开披露 </p>
                <p>我们仅会在以下情况下，公开披露您的个人信息： </p>
                <p>2.2.3.1获得您明确同意后； </p>
                <p>2.2.3.2基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息，主要包括以下情景： </p>
                <p>a.与国家安全、国防安全直接相关的； </p>
                <p>b.与公共安全、公共卫生、重大公共利益直接相关的； </p>
                <p>c.与犯罪侦查、起诉、审判和判决执行等直接相关的； </p>
                <p>d.出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的； </p>
                <p>e.所收集的您的个人信息是您自行向社会公众公开的； </p>
                <p>f.从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道； </p>
                <p>g.根据您的要求签订或履行合同所必需的； </p>
                <p>h.用于维护软件及相关服务的安全稳定运行所必需的，例如发现、处置软件及相关服务的故障； </p>
                <p>i.为合法的新闻报道所必需的； </p>
                <p>j.学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的； </p>
                <p>k.法律法规规定的其他情形。 </p>
                <p>特别提示您注意，如信息无法单独或结合其他信息识别到您的个人身份，其不属于法律意义上您的个人信息；当您的信息可以单独或结合其他信息识别到您的个人身份时或我们将无法与任何特定个人信息建立联系的数据与其他您的个人信息结合使用时，这些信息在结合使用期间，将作为您的个人信息按照本隐私政策处理与保护。</p>
                <p></p>
                <p>2.3.我们如何存储和保护信息 </p>
                <p>2.3.1 存储地点 </p>
                <p>我们依照法律法规的规定，将在境内运营过程中收集和产生的您的个人信息存储于中华人民共和国境内。目前，我们不会将上述信息传输至境外，如果我们向境外传输，我们将遵循相关国家规定或者征求您的同意。 </p>
                <p>2.3.2存储期限 </p>
                <p>我们仅在为提供关节通及服务之目的所必须的期间内保存您的个人信息。超出必要期限后，我们将对您的个人信息进行删除或匿名化处理，但法律法规另有规定的除外。 </p>
                <p>2.3.3 技术措施与数据保护措施 </p>
                <p>我们已使用符合业界标准的安全防护措施保护您提供的个人信息，防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会采取一切合理可行的措施，保护您的个人信息。例如，我们会使用加密技术确保数据的保密性；我们会使用受信赖的保护机制防止数据遭到恶意攻击；我们会部署访问控制机制，确保只有授权人员才可访问个人信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。 </p>
                <p>2.3.4 安全事件处理 </p>
                <p>在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。 同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。</p>
                <p></p>
                <p>3.您的权利 </p>
                <p>按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的个人信息行使以下权利： </p>
                <p>3.1访问您的个人信息 </p>
                <p>如果您希望访问或编辑 您的账户中个人账户及注册问卷信息，您可以通过访问个人中心—>我的信息进行查看。   </p>
                <p>3.2访问您的健康记录 </p>
                <p>如果您希望访问或编辑 您的每日健康记录，您可以通过访问个人中心—>健康记录进行查看。</p>
                <p>3.3访问您的收到的手术康复视频 </p>
                <p>如果您希望访问收到的手术康复视频，您可以通过访问个人中心—>康复方案进行查看。 </p>
                <p>3.4访问您的收到的非手术运动视频 </p>
                <p>如果您希望访问您的收到的非手术运动视频，您可以通过访问个人中心—>非术后视频。 </p>
                <p>3.5约束信息系统自动决策 </p>
                <p>在某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制做出决定。如果这些决定显著影响您的合法权益，您有权要求我们做出解释式。 </p>
                <p>3.6响应您的上述请求 </p>
                <p>为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。我们将在十五天内做出答复。 </p>
                <p>在以下情形中，我们将无法响应您的请求： </p>
                <p>a、与个人信息控制者履行法律法规规定的义务相关的； </p>
                <p>b、与国家安全、国防安全直接相关的； </p>
                <p>c、与公共安全、公共卫生、重大公共利益直接相关的； </p>
                <p>d、与犯罪侦查、起诉、审判和执行判决等直接相关的； </p>
                <p>e、个人信息控制者有充分证据表明个人信息主体存在主观恶意或滥用权利的； </p>
                <p>f、出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的； </p>
                <p>g、响应个人信息主体的请求将导致个人信息主体或其他个人、组织的合法权益受到严重损害的； </p>
                <p>h、涉及商业秘密的。</p>
                <p></p>
                <p>4.本政策的适用及更新 </p>
                <p>我们可能会不时对《关节通隐私政策及协议》进行修订，当《关节通隐私政策及协议》发生重大变更修订时，我们会在版本更新后以推送通知或弹窗等形式向您展示变更后的内容。请您注意，只有在您确认修订后的《关节通隐私政策》后，我们才会按照修订后的《关节通隐私政策及协议》收集、使用、处理和存储您的个人信息；您可以选择不同意修订后的《关节通隐私政策及协议》，但可能导致您无法使用关节通的部分服务及功能。 </p>
                <p>未经您明确同意，我们不会削减您按照本隐私政策所应享有的权利。我们会在本页面上发布对本政策所做的任何变更。 </p>
                <p>本政策所指的重大变更包括但不限于： </p>
                <p>a、我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等； </p>
                <p>b、我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等； </p>
                <p>c、个人信息共享、转让或公开披露的主要对象发生变化； </p>
                <p>d、您参与个人信息处理方面的权利及其行使方式发生重大变化； </p>
                <p>e、我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时； </p>
                <p>f、个人信息安全影响评估报告表明存在高风险时。 </p>
                <p></p>
                <p>5.如何联系我们 </p>
                <p>我们已经设立了个人信息保护负责人员，如果您对本隐私政策有任何疑问、意见或建议，通过以下方式与我们联系： </p>
                <p>电话：4000-365-880 </p>
                <p>如果您对我们的回复不满意，特别是我们的个人信息处理行为损害了您的合法权益，您还可以向网信、电信、公安及工商等监管部门进行投诉或举报。 </p>
                <br>
                <br>
            </div>
        </ActionSheet>

        <RLFooter/>
    </div>
</template>

<script>
    import $ from 'jquery'
    import WebUploader from 'webuploader'
    import {Form as vfrom,Field as vfield,Button,Uploader as vuploader,ActionSheet,Checkbox as vcheckbox,Toast,Cell,CellGroup,Progress,Popup,Picker,Radio,RadioGroup} from 'vant';
    // import {uploadImgOrVideo}  from '@/api/upload';
    // import {uploadRehabilitationFile}  from '@/api/rehabilitation';
    import {getUser} from '@/api/user';

    export default {
        name: 'toUploadFiles',
        components: {
            Button,vuploader,vfrom,vfield,ActionSheet,vcheckbox,Cell,CellGroup,Progress,Popup,Picker,Radio,RadioGroup
        },
        data(){
            return {
                fileInfo:null,
                fileid:'',
                files:[],
                type:'',
                substatus:true,
                user:{
                    userName:'',
                    nickName:'',
                    idCard:'',
                    phone:'',
                    gender:'1',
                    birthday:'',
                    id:'',
                    pid:''
                },
                showFwxy:false,
                showYszc:false,
                isagree:false,
                agreeStatus:true,
                uploader:null,
                fileList:[],
                fileIn:null,
                uploadtype:'dataUrl',
                uploadPer:0,
                video_name:'safs',
                video_name_cn:'站立位静态姿势（正面）',
                pain:'无',
                showPicker:false,
                url:'https://guanjietong.rilintech.com/',
                video_url:'http://guanjietong.rilintech.com/站立位静态姿势（正面）.mp4',
            };
        },
        computed:{
            customFieldName(){
                let res= {
                    text: 'text',
                    values: 'value',

                }
                return res;
            },
            opts(){
                let res= [
                    {text : "站立位静态姿势（正面）", value : "safs"},
                    {text : "站立位静态姿势（左）", value : "sssal"},
                    {text : "站立位静态姿势（右）", value : "sssar"},
                    {text : "肩前上举（左）", value : "frontl"},
                    {text : "肩前上举（右）", value : "frontr"},
                    {text : "膝关节屈伸活动（左）", value : "kjl"},
                    {text : "膝关节屈伸活动（右）", value : "kjr"},
                    {text : "过顶深蹲（左）", value : "osil"},
                    {text : "过顶深蹲（正面）", value : "osip"},
                    {text : "过顶深蹲（右）", value : "osir"},
                    {text : "单腿站立平衡（左）", value : "sooll"},
                    {text : "单腿站立平衡（右）", value : "soolr"},
                ]
                return res;
            }
        },
        methods:{
            onSubmit() {
                this.toupload()

            },
            onConfirm(value){
                console.log(value)
                this.video_name= value.value
                this.video_name_cn= value.text
                this.video_url = this.url+value.text+'.mp4'
                this.showPicker = false;

            },
            beforeRead(file){
                console.log(file)
                if(file.type.indexOf("video")!=-1){
                    this.uploadtype = 'file'
                }else if(file.type.indexOf("image")!=-1){
                    this.uploadtype = 'dataUrl'
                }
                return true
            },
            afterRead(file) {
                // 此时可以自行将文件上传至服务器
                // console.log(file);
                file.status = 'uploading';
                file.message = '上传中...';
                console.info(file);
                this.type = file.file.type
                file.status = 'success';
                file.message = '上传成功';
                this.substatus = false


                this.uploader.addFiles( file.file )
            },
            // deleteFile(){
            //     // console.log("删除文件")
            //     // console.log(file)
            //     this.uploader.removeFile(this.uploader.getFiles()[0]);
            //     console.log(this.uploader.getFiles())
            //     return true
            // },
            initBaseInfo(){

                getUser().then((res) => {
                    console.info(res.data);

                    if(res.data){
                        if(res.data.name !=''){
                            this.substatus = true
                        }
                        this.user.id=res.data.id;
                        this.user.name=res.data.name;
                        this.user.birthday=res.data.birthday;
                        // this.user.idCard=response.data.idCard;
                        this.user.telephone=res.data.telephone;
                        this.user.gender=res.data.gender+'';
                        this.user.address=res.data.address;
                        this.user.detail_address=res.data.detail_address;
                        // this.initBaseInfo();

                    }
                });
            },
            onOversize(file) {
                console.log(file);
                Toast('文件大小不能超过 10MB');
            },
            onclickupload(Event) {
                console.log('12313');
                console.log(Event);
            },
            showFWXY(){
                this.showFwxy = true;
            },
            showYSZC(){
                this.showYszc = true;
            },
            changeAgree(ischecked){
                if(ischecked == true){
                    this.agreeStatus = false
                }else{
                    this.agreeStatus = true

                }
            },
            toupload(){
                // console.log(this.video_name)
                // console.log(this.pain)
                this.uploader.option( 'formData', {
                    video_name:this.video_name,
                    video_name_cn:this.video_name_cn,
                    pain:this.pain
                });

                this.uploader.upload()
            },

            // openfile(){
            //     this.$emit('showCityName',data);
            // }
        },
        mounted:function(){
            this.uploader = WebUploader.create({
                // swf文件路径
                swf:'../static/Uploader.swf',
                // 文件接收服务端。
                server: 'http://localhost:8080/api/v1/weixin_pictures/createIdentifyVideo.json',
                // dnd 指定Drag And Drop拖拽的容器，如果不指定，则不启动
                // 禁用全局拖拽，否则在没有启动拖拽容器的情况下，视频拖进来后会直接在浏览器内播放。
                disableGlobalDnd: true,

                // 选择文件的按钮。可选。内部根据当前运行是创建，可能是input元素，也可能是flash.
                // pick: {
                //     id: '#picker',                     // 对应 html 中的 picker
                //     innerHTML: '选择文件',   // 按钮上显示的文字
                //     multiple: false,                  // 多文件选择
                // },

                // 允许视频和图片类型的文件上传。
                accept: {
                    title: 'Video',
                    extensions: 'jpg,jpeg,mp4,bmp,png,mov,gif',      // 可以多个后缀，以逗号分隔， 不要有空格
                    // mimeTypes: 'image/*,video/*'
                },

                // 只允许选择图片文件。
                //accept: {
                // title: 'Images',
                //  extensions: '',
                //  mimeTypes: ''
                //}

                // thumb配置生成缩略图的选项， 此项交由后台完成， 所以前台未配置

                // 自动上传暂时关闭，使用多文件队列上传， 如果值为true，那么在选择完文件后，将直接开始上传文件，因为我还要做一些其他处理，故选择false。
                auto: false,

                //是否允许在文件传输时提前把下一个文件准备好。 对于一个文件的准备工作比较耗时，比如图片压缩，md5序列化。 如果能提前在当前文件传输期处理，可以节省总体耗时。
                prepareNextFile: true,

                // 可选，是否要分片处理大文件上传
                chunked: true,
                // 如果要分片，分多大一片？这里我设置为2M, 如需更大值，可能需要需修改php.ini等配置
                chunkSize:2*1024*1024,
                // 如果某个分片由于网络问题出错，允许自动重传多少次
                chunkRetry: 3,
                // 上传并发数，允许同时上传最大进程数，默认3
                threads:5,

                // formData {Object} [可选] [默认值：{}] 文件上传请求的参数表，每次发送都会发送此对象中的参数。 其实就是post中的表单数据，可自定义字段。
                formData: {
                    video_name:this.video_name,
                    video_name_cn:this.video_name_cn,
                    pain:this.pain
                    // video_name: $scope.video_name.value,     // 这里是我的业务数据，你可以自定义或者去掉此项都可以
                    // video_name_cn: $scope.video_name.label,     // 这里是我的业务数据，你可以自定义或者去掉此项都可以
                    // pain: $scope.pain.value    // 这里是我的业务数据，你可以自定义或者去掉此项都可以
                },
                //[可选] 验证文件总数量, 超出9个文件则不允许加入队列。
                fileNumLimit: 9,
                // 验证文件总大小是否超出限制（2G）, 超出则不允许加入队列。根据需要进行设置。除了前面几个，其它都是可选项
                fileSizeLimit: 1024*1024*1024*2,
                // 验证单个文件大小是否超出限制（2G）, 超出则不允许加入队列。
                fileSingleSizeLimit: 1024*1024*1024*2,
                // [可选] 去重， 根据文件名字、文件大小和最后修改时间来生成hash Key.
                duplicate: true,
                // 不压缩image, 默认如果是jpeg，文件上传前会压缩一把再上传！
                // resize: false,
                // 压缩选项
                compress: {
                    // 如果压缩后比源文件大，则不压缩，图片有可能压缩后比原文件还大，需设置此项
                    noCompressIfLarger: true,
                },
            });
            this.uploader.on( 'beforeFileQueued', function( file) {
                console.log('文件加入队前',file)
            });
            // 当有文件被添加进队列的时候
            this.uploader.on( 'fileQueued', file => {
                console.log('文件加入队列后')
                console.log(file)
                // console.log(this.video_name)
                // console.log(this.pain)
                this.fileList.push(file.name)
                console.log(this.fileList)
                // var $list = $('#fileList')
                // $list.append( '<div id="' + file.id + '" class="item">' +
                //     '<h4 class="info">' + file.name + '</h4>' +
                //     '<p class="state">等待上传...</p>' +
                //     '</div>' );

            });

            // 文件上传过程中创建进度条实时显示。
            this.uploader.on( 'uploadProgress', (file, percentage) =>{
                console.log('文件上传中',file,percentage)
                this.uploadPer = Math.floor(percentage*100)
                console.log(this.uploadPer)
                // var $li = $( '#'+file.id ),
                //     $percent = $li.find('.progress .progress-bar');
                // // 避免重复创建
                // if ( !$percent.length ) {
                //     $percent = $('<div class="progress progress-striped active">' +
                //         '<div class="progress-bar" role="progressbar" style="width: 0%">' +
                //         '</div>' +
                //         '</div>').appendTo( $li ).find('.progress-bar');
                // }
                // $li.find('p.state').text('上传中');
                // $percent.css( 'width', percentage * 100 + '%' );  //根据上传进度改变进度条宽度
            });
            this.uploader.on( 'uploadSuccess', function( file,response ) {
                console.log('文件上传成功',file,response)
                $( '#'+file.id ).find('p.state').text('已上传');
            });
            this.uploader.on( 'uploadError', function( file,reason ) {
                console.log('文件上传失败',file,reason)
                $( '#'+file.id ).find('p.state').text('上传出错');
            });
            this.uploader.on( 'uploadComplete', file  => {
                this.$router.push({path:'/identify'});
                this.$toast.success('上传成功');

                console.log('文件上传完成')
                $( '#'+file.id ).find('.progress').fadeOut();
            });



        },
        created() {
            this.initBaseInfo();
        },
    }
</script>
